import { default as indexEn8r7rFLqmMeta } from "/home/vfy/website-git-prod/pages/index.vue?macro=true";
import { default as _91accoId_932QFck2py62Meta } from "/home/vfy/website-git-prod/pages/acco/[accoId].vue?macro=true";
import { default as _91slug_93bWkG7FbchpMeta } from "/home/vfy/website-git-prod/pages/acties/[lang]/[slug].vue?macro=true";
import { default as indexalZSv53ZlQMeta } from "/home/vfy/website-git-prod/pages/acties/[lang]/index.vue?macro=true";
import { default as _91slug_93EsahpI1aK5Meta } from "/home/vfy/website-git-prod/pages/blog/[lang]/[slug].vue?macro=true";
import { default as _91slug_93MyJjYejhxyMeta } from "/home/vfy/website-git-prod/pages/boeken/[slug].vue?macro=true";
import { default as contactgdE8CJYY9WMeta } from "/home/vfy/website-git-prod/pages/contact.vue?macro=true";
import { default as cookie_45beleidJyFBuUfnrXMeta } from "/home/vfy/website-git-prod/pages/cookie-beleid.vue?macro=true";
import { default as disclaimerEuLGRgOqzrMeta } from "/home/vfy/website-git-prod/pages/disclaimer.vue?macro=true";
import { default as favorietenLpEqobaMHpMeta } from "/home/vfy/website-git-prod/pages/favorieten.vue?macro=true";
import { default as _91_46_46_46path_93hwj6tZDgxwMeta } from "/home/vfy/website-git-prod/pages/ho-[homeownerCode]/[...path].vue?macro=true";
import { default as kwaliteitsgarantiesKtfxFv5E8Meta } from "/home/vfy/website-git-prod/pages/kwaliteitsgarantie.vue?macro=true";
import { default as last_45minutesWGD8QDqcCEMeta } from "/home/vfy/website-git-prod/pages/last-minutes.vue?macro=true";
import { default as mijn_45vakantiehuis_45verhuren48mjcdhd79Meta } from "/home/vfy/website-git-prod/pages/mijn-vakantiehuis-verhuren.vue?macro=true";
import { default as _91slug_93l5TPw18DrmMeta } from "/home/vfy/website-git-prod/pages/news/[lang]/[slug].vue?macro=true";
import { default as indexTQG8JRd7esMeta } from "/home/vfy/website-git-prod/pages/news/[lang]/index.vue?macro=true";
import { default as newsletter_45confirmVjDw3ssxO9Meta } from "/home/vfy/website-git-prod/pages/newsletter-confirm.vue?macro=true";
import { default as over_45villa_45for_45youvLa8fqmOZCMeta } from "/home/vfy/website-git-prod/pages/over-villa-for-you.vue?macro=true";
import { default as _91slug_93F7xBtIpu3BMeta } from "/home/vfy/website-git-prod/pages/parken/[lang]/[slug].vue?macro=true";
import { default as indexugmvThSkEjMeta } from "/home/vfy/website-git-prod/pages/parken/[lang]/index.vue?macro=true";
import { default as indexNhWpjDAUAyMeta } from "/home/vfy/website-git-prod/pages/partners/index.vue?macro=true";
import { default as integratieGoZGn4S4ieMeta } from "/home/vfy/website-git-prod/pages/partners/integratie.vue?macro=true";
import { default as joint_45promotionsMhagwTbkkZMeta } from "/home/vfy/website-git-prod/pages/partners/joint-promotions.vue?macro=true";
import { default as link_45partnersKAmn1jcfGIMeta } from "/home/vfy/website-git-prod/pages/partners/link-partners.vue?macro=true";
import { default as reisbureau_45reisagentI1GwpawXFCMeta } from "/home/vfy/website-git-prod/pages/partners/reisbureau-reisagent.vue?macro=true";
import { default as privacy_45huiseigenarenFxczxCzZpUMeta } from "/home/vfy/website-git-prod/pages/privacy-huiseigenaren.vue?macro=true";
import { default as privacyYMnEf7VFp4Meta } from "/home/vfy/website-git-prod/pages/privacy.vue?macro=true";
import { default as reisen_45atHyaEhoU5tnMeta } from "/home/vfy/website-git-prod/pages/reisen-at.vue?macro=true";
import { default as reisen_45ch8XNnVolo7qMeta } from "/home/vfy/website-git-prod/pages/reisen-ch.vue?macro=true";
import { default as reisen_45degQZ0O30nhwMeta } from "/home/vfy/website-git-prod/pages/reisen-de.vue?macro=true";
import { default as _91_46_46_46path_93wgPb0C5W3XMeta } from "/home/vfy/website-git-prod/pages/sp-[partnerCode]/[...path].vue?macro=true";
import { default as sterrenclassificatieWIVnirEf2sMeta } from "/home/vfy/website-git-prod/pages/sterrenclassificatie.vue?macro=true";
import { default as aanvraagSsgDIaPZf1Meta } from "/home/vfy/website-git-prod/pages/succes/aanvraag.vue?macro=true";
import { default as boekingVPhCIp3TbqMeta } from "/home/vfy/website-git-prod/pages/succes/boeking.vue?macro=true";
import { default as optiey4G7zVjh7dMeta } from "/home/vfy/website-git-prod/pages/succes/optie.vue?macro=true";
import { default as teamNaw7PbTa5rMeta } from "/home/vfy/website-git-prod/pages/team.vue?macro=true";
import { default as vacances_45chT9AAg0peXYMeta } from "/home/vfy/website-git-prod/pages/vacances-ch.vue?macro=true";
import { default as vacancesRUHiGh4fDSMeta } from "/home/vfy/website-git-prod/pages/vacances.vue?macro=true";
import { default as vacaturesRn5oouahwBMeta } from "/home/vfy/website-git-prod/pages/vacatures.vue?macro=true";
import { default as _91_46_46_46slug_93tDiJeRUwMlMeta } from "/home/vfy/website-git-prod/pages/vakantiehuis-[country]/[...slug].vue?macro=true";
import { default as vakantiehuish4Ihse8GEVMeta } from "/home/vfy/website-git-prod/pages/vakantiehuis.vue?macro=true";
import { default as vakantiehuizen_45beiMSyjbCn6YMeta } from "/home/vfy/website-git-prod/pages/vakantiehuizen-be.vue?macro=true";
import { default as vakantiehuizenL9WCIpXsW4Meta } from "/home/vfy/website-git-prod/pages/vakantiehuizen.vue?macro=true";
import { default as veelgestelde_45vragenLQP3kkNChxMeta } from "/home/vfy/website-git-prod/pages/veelgestelde-vragen.vue?macro=true";
import { default as verzekeringenWKNFnqPLkYMeta } from "/home/vfy/website-git-prod/pages/verzekeringen.vue?macro=true";
import { default as villasy2qAmkFonMMeta } from "/home/vfy/website-git-prod/pages/villas.vue?macro=true";
import { default as component_45stub1ScE3GkDJwMeta } from "/home/vfy/website-git-prod/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub1ScE3GkDJw } from "/home/vfy/website-git-prod/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/vfy/website-git-prod/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "acco-accoId___nl",
    path: "/nl/acco/:accoId()/",
    component: () => import("/home/vfy/website-git-prod/pages/acco/[accoId].vue").then(m => m.default || m)
  },
  {
    name: "acco-accoId___en",
    path: "/en/acco/:accoId()/",
    component: () => import("/home/vfy/website-git-prod/pages/acco/[accoId].vue").then(m => m.default || m)
  },
  {
    name: "acco-accoId___de",
    path: "/de/acco/:accoId()/",
    component: () => import("/home/vfy/website-git-prod/pages/acco/[accoId].vue").then(m => m.default || m)
  },
  {
    name: "acco-accoId___fr",
    path: "/fr/acco/:accoId()/",
    component: () => import("/home/vfy/website-git-prod/pages/acco/[accoId].vue").then(m => m.default || m)
  },
  {
    name: "acties-lang-slug",
    path: "/acties/:lang()/:slug()",
    component: () => import("/home/vfy/website-git-prod/pages/acties/[lang]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "acties-lang",
    path: "/acties/:lang()",
    component: () => import("/home/vfy/website-git-prod/pages/acties/[lang]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-lang-slug",
    path: "/blog/:lang()/:slug()",
    component: () => import("/home/vfy/website-git-prod/pages/blog/[lang]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "boeken-slug___nl",
    path: "/nl/boeken/:slug()/",
    component: () => import("/home/vfy/website-git-prod/pages/boeken/[slug].vue").then(m => m.default || m)
  },
  {
    name: "boeken-slug___en",
    path: "/en/book/:slug()/",
    component: () => import("/home/vfy/website-git-prod/pages/boeken/[slug].vue").then(m => m.default || m)
  },
  {
    name: "boeken-slug___de",
    path: "/de/buchen/:slug()/",
    component: () => import("/home/vfy/website-git-prod/pages/boeken/[slug].vue").then(m => m.default || m)
  },
  {
    name: "boeken-slug___fr",
    path: "/fr/reserver/:slug()/",
    component: () => import("/home/vfy/website-git-prod/pages/boeken/[slug].vue").then(m => m.default || m)
  },
  {
    name: "contact___nl",
    path: "/nl/contact/",
    component: () => import("/home/vfy/website-git-prod/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/en/contact/",
    component: () => import("/home/vfy/website-git-prod/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___de",
    path: "/de/kontakt/",
    component: () => import("/home/vfy/website-git-prod/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___fr",
    path: "/fr/contact/",
    component: () => import("/home/vfy/website-git-prod/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "cookie-beleid___nl",
    path: "/nl/cookie-beleid/",
    component: () => import("/home/vfy/website-git-prod/pages/cookie-beleid.vue").then(m => m.default || m)
  },
  {
    name: "cookie-beleid___en",
    path: "/en/cookie-policy/",
    component: () => import("/home/vfy/website-git-prod/pages/cookie-beleid.vue").then(m => m.default || m)
  },
  {
    name: "cookie-beleid___de",
    path: "/de/cookie-politik/",
    component: () => import("/home/vfy/website-git-prod/pages/cookie-beleid.vue").then(m => m.default || m)
  },
  {
    name: "cookie-beleid___fr",
    path: "/fr/utilisation-des-cookies/",
    component: () => import("/home/vfy/website-git-prod/pages/cookie-beleid.vue").then(m => m.default || m)
  },
  {
    name: "disclaimer___nl",
    path: "/nl/disclaimer/",
    component: () => import("/home/vfy/website-git-prod/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: "disclaimer___en",
    path: "/en/disclaimer/",
    component: () => import("/home/vfy/website-git-prod/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: "disclaimer___de",
    path: "/de/haftungsausschluss/",
    component: () => import("/home/vfy/website-git-prod/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: "disclaimer___fr",
    path: "/fr/clause-de-non-responsabilite/",
    component: () => import("/home/vfy/website-git-prod/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: "favorieten___nl",
    path: "/nl/favorieten/",
    component: () => import("/home/vfy/website-git-prod/pages/favorieten.vue").then(m => m.default || m)
  },
  {
    name: "favorieten___en",
    path: "/en/favorites/",
    component: () => import("/home/vfy/website-git-prod/pages/favorieten.vue").then(m => m.default || m)
  },
  {
    name: "favorieten___de",
    path: "/de/favoriten/",
    component: () => import("/home/vfy/website-git-prod/pages/favorieten.vue").then(m => m.default || m)
  },
  {
    name: "favorieten___fr",
    path: "/fr/favoris/",
    component: () => import("/home/vfy/website-git-prod/pages/favorieten.vue").then(m => m.default || m)
  },
  {
    name: "ho-homeownerCode-path___nl",
    path: "/nl/ho-:homeownerCode()/:path(.*)*/",
    component: () => import("/home/vfy/website-git-prod/pages/ho-[homeownerCode]/[...path].vue").then(m => m.default || m)
  },
  {
    name: "ho-homeownerCode-path___en",
    path: "/en/ho-:homeownerCode()/:path(.*)*/",
    component: () => import("/home/vfy/website-git-prod/pages/ho-[homeownerCode]/[...path].vue").then(m => m.default || m)
  },
  {
    name: "ho-homeownerCode-path___de",
    path: "/de/ho-:homeownerCode()/:path(.*)*/",
    component: () => import("/home/vfy/website-git-prod/pages/ho-[homeownerCode]/[...path].vue").then(m => m.default || m)
  },
  {
    name: "ho-homeownerCode-path___fr",
    path: "/fr/ho-:homeownerCode()/:path(.*)*/",
    component: () => import("/home/vfy/website-git-prod/pages/ho-[homeownerCode]/[...path].vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl/",
    component: () => import("/home/vfy/website-git-prod/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en/",
    component: () => import("/home/vfy/website-git-prod/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de/",
    component: () => import("/home/vfy/website-git-prod/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr/",
    component: () => import("/home/vfy/website-git-prod/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kwaliteitsgarantie___nl",
    path: "/nl/villa-for-you-kwaliteitsgarantie/",
    component: () => import("/home/vfy/website-git-prod/pages/kwaliteitsgarantie.vue").then(m => m.default || m)
  },
  {
    name: "kwaliteitsgarantie___en",
    path: "/en/villa-for-you-quality-guarantee/",
    component: () => import("/home/vfy/website-git-prod/pages/kwaliteitsgarantie.vue").then(m => m.default || m)
  },
  {
    name: "kwaliteitsgarantie___de",
    path: "/de/villa-for-you-qualitatsgarantie/",
    component: () => import("/home/vfy/website-git-prod/pages/kwaliteitsgarantie.vue").then(m => m.default || m)
  },
  {
    name: "kwaliteitsgarantie___fr",
    path: "/fr/garantie-de-qualite-villa-for-you/",
    component: () => import("/home/vfy/website-git-prod/pages/kwaliteitsgarantie.vue").then(m => m.default || m)
  },
  {
    name: "last-minutes___nl",
    path: "/nl/last-minute/",
    meta: last_45minutesWGD8QDqcCEMeta || {},
    component: () => import("/home/vfy/website-git-prod/pages/last-minutes.vue").then(m => m.default || m)
  },
  {
    name: "last-minutes___en",
    path: "/en/last-minute/",
    meta: last_45minutesWGD8QDqcCEMeta || {},
    component: () => import("/home/vfy/website-git-prod/pages/last-minutes.vue").then(m => m.default || m)
  },
  {
    name: "last-minutes___de",
    path: "/de/last-minute/",
    meta: last_45minutesWGD8QDqcCEMeta || {},
    component: () => import("/home/vfy/website-git-prod/pages/last-minutes.vue").then(m => m.default || m)
  },
  {
    name: "last-minutes___fr",
    path: "/fr/derniere-minute/",
    meta: last_45minutesWGD8QDqcCEMeta || {},
    component: () => import("/home/vfy/website-git-prod/pages/last-minutes.vue").then(m => m.default || m)
  },
  {
    name: "mijn-vakantiehuis-verhuren___nl",
    path: "/nl/mijn-vakantiehuis-verhuren/",
    component: () => import("/home/vfy/website-git-prod/pages/mijn-vakantiehuis-verhuren.vue").then(m => m.default || m)
  },
  {
    name: "mijn-vakantiehuis-verhuren___en",
    path: "/en/rent-out-my-holiday-home/",
    component: () => import("/home/vfy/website-git-prod/pages/mijn-vakantiehuis-verhuren.vue").then(m => m.default || m)
  },
  {
    name: "mijn-vakantiehuis-verhuren___de",
    path: "/de/vermiete-mein-ferienhaus/",
    component: () => import("/home/vfy/website-git-prod/pages/mijn-vakantiehuis-verhuren.vue").then(m => m.default || m)
  },
  {
    name: "mijn-vakantiehuis-verhuren___fr",
    path: "/fr/louer-ma-maison-de-vacances/",
    component: () => import("/home/vfy/website-git-prod/pages/mijn-vakantiehuis-verhuren.vue").then(m => m.default || m)
  },
  {
    name: "news-lang-slug",
    path: "/news/:lang()/:slug()",
    component: () => import("/home/vfy/website-git-prod/pages/news/[lang]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news-lang",
    path: "/news/:lang()",
    component: () => import("/home/vfy/website-git-prod/pages/news/[lang]/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletter-confirm___nl",
    path: "/nl/newsletter-confirm/",
    component: () => import("/home/vfy/website-git-prod/pages/newsletter-confirm.vue").then(m => m.default || m)
  },
  {
    name: "newsletter-confirm___en",
    path: "/en/newsletter-confirm/",
    component: () => import("/home/vfy/website-git-prod/pages/newsletter-confirm.vue").then(m => m.default || m)
  },
  {
    name: "newsletter-confirm___de",
    path: "/de/newsletter-confirm/",
    component: () => import("/home/vfy/website-git-prod/pages/newsletter-confirm.vue").then(m => m.default || m)
  },
  {
    name: "newsletter-confirm___fr",
    path: "/fr/newsletter-confirm/",
    component: () => import("/home/vfy/website-git-prod/pages/newsletter-confirm.vue").then(m => m.default || m)
  },
  {
    name: "over-villa-for-you___nl",
    path: "/nl/over-villa-for-you/",
    component: () => import("/home/vfy/website-git-prod/pages/over-villa-for-you.vue").then(m => m.default || m)
  },
  {
    name: "over-villa-for-you___en",
    path: "/en/about-villa-for-you/",
    component: () => import("/home/vfy/website-git-prod/pages/over-villa-for-you.vue").then(m => m.default || m)
  },
  {
    name: "over-villa-for-you___de",
    path: "/de/uber-villa-for-you/",
    component: () => import("/home/vfy/website-git-prod/pages/over-villa-for-you.vue").then(m => m.default || m)
  },
  {
    name: "over-villa-for-you___fr",
    path: "/fr/a-propos-de-villa-for-you/",
    component: () => import("/home/vfy/website-git-prod/pages/over-villa-for-you.vue").then(m => m.default || m)
  },
  {
    name: "parken-lang-slug",
    path: "/parken/:lang()/:slug()",
    component: () => import("/home/vfy/website-git-prod/pages/parken/[lang]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "parken-lang",
    path: "/parken/:lang()",
    component: () => import("/home/vfy/website-git-prod/pages/parken/[lang]/index.vue").then(m => m.default || m)
  },
  {
    name: "partners___nl",
    path: "/nl/partners/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: "partners___en",
    path: "/en/partners/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: "partners___de",
    path: "/de/partners/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: "partners___fr",
    path: "/fr/partenaires/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: "partners-integratie___nl",
    path: "/nl/partners/integratie/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/integratie.vue").then(m => m.default || m)
  },
  {
    name: "partners-integratie___en",
    path: "/en/partners/integration/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/integratie.vue").then(m => m.default || m)
  },
  {
    name: "partners-integratie___de",
    path: "/de/partners/datenfeed-integration/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/integratie.vue").then(m => m.default || m)
  },
  {
    name: "partners-integratie___fr",
    path: "/fr/partenaires/integration/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/integratie.vue").then(m => m.default || m)
  },
  {
    name: "partners-joint-promotions___nl",
    path: "/nl/partners/joint-promotions/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/joint-promotions.vue").then(m => m.default || m)
  },
  {
    name: "partners-joint-promotions___en",
    path: "/en/partners/joint-promotions/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/joint-promotions.vue").then(m => m.default || m)
  },
  {
    name: "partners-joint-promotions___de",
    path: "/de/partners/gemeinsame-aktionen/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/joint-promotions.vue").then(m => m.default || m)
  },
  {
    name: "partners-joint-promotions___fr",
    path: "/fr/partenaires/promotions-conjointes/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/joint-promotions.vue").then(m => m.default || m)
  },
  {
    name: "partners-link-partners___nl",
    path: "/nl/partners/link-partners/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/link-partners.vue").then(m => m.default || m)
  },
  {
    name: "partners-link-partners___en",
    path: "/en/partners/link-partners/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/link-partners.vue").then(m => m.default || m)
  },
  {
    name: "partners-link-partners___de",
    path: "/de/partners/partner-verlinken/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/link-partners.vue").then(m => m.default || m)
  },
  {
    name: "partners-link-partners___fr",
    path: "/fr/partenaires/liaison/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/link-partners.vue").then(m => m.default || m)
  },
  {
    name: "partners-reisbureau-reisagent___nl",
    path: "/nl/partners/reisbureau-reisagent/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/reisbureau-reisagent.vue").then(m => m.default || m)
  },
  {
    name: "partners-reisbureau-reisagent___en",
    path: "/en/partners/travel-agencies/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/reisbureau-reisagent.vue").then(m => m.default || m)
  },
  {
    name: "partners-reisbureau-reisagent___de",
    path: "/de/partners/reiseburo-reiseagentur/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/reisbureau-reisagent.vue").then(m => m.default || m)
  },
  {
    name: "partners-reisbureau-reisagent___fr",
    path: "/fr/partenaires/agence-de-voyages/",
    component: () => import("/home/vfy/website-git-prod/pages/partners/reisbureau-reisagent.vue").then(m => m.default || m)
  },
  {
    name: "privacy-huiseigenaren___nl",
    path: "/nl/privacy-huiseigenaren/",
    component: () => import("/home/vfy/website-git-prod/pages/privacy-huiseigenaren.vue").then(m => m.default || m)
  },
  {
    name: "privacy-huiseigenaren___en",
    path: "/en/privacy-homeowners/",
    component: () => import("/home/vfy/website-git-prod/pages/privacy-huiseigenaren.vue").then(m => m.default || m)
  },
  {
    name: "privacy-huiseigenaren___de",
    path: "/de/datenschutz-hauseigentumer/",
    component: () => import("/home/vfy/website-git-prod/pages/privacy-huiseigenaren.vue").then(m => m.default || m)
  },
  {
    name: "privacy-huiseigenaren___fr",
    path: "/fr/declaration-confidentialite-proprietaires/",
    component: () => import("/home/vfy/website-git-prod/pages/privacy-huiseigenaren.vue").then(m => m.default || m)
  },
  {
    name: "privacy___nl",
    path: "/nl/privacy/",
    component: () => import("/home/vfy/website-git-prod/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___en",
    path: "/en/privacy/",
    component: () => import("/home/vfy/website-git-prod/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___de",
    path: "/de/datenschutz/",
    component: () => import("/home/vfy/website-git-prod/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___fr",
    path: "/fr/confidentialite/",
    component: () => import("/home/vfy/website-git-prod/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "reisen-at___nl",
    path: "/nl/reisen-at/",
    component: () => import("/home/vfy/website-git-prod/pages/reisen-at.vue").then(m => m.default || m)
  },
  {
    name: "reisen-at___en",
    path: "/en/reisen-at/",
    component: () => import("/home/vfy/website-git-prod/pages/reisen-at.vue").then(m => m.default || m)
  },
  {
    name: "reisen-at___de",
    path: "/de/reisen-at/",
    component: () => import("/home/vfy/website-git-prod/pages/reisen-at.vue").then(m => m.default || m)
  },
  {
    name: "reisen-at___fr",
    path: "/fr/reisen-at/",
    component: () => import("/home/vfy/website-git-prod/pages/reisen-at.vue").then(m => m.default || m)
  },
  {
    name: "reisen-ch___nl",
    path: "/nl/reisen-ch/",
    component: () => import("/home/vfy/website-git-prod/pages/reisen-ch.vue").then(m => m.default || m)
  },
  {
    name: "reisen-ch___en",
    path: "/en/reisen-ch/",
    component: () => import("/home/vfy/website-git-prod/pages/reisen-ch.vue").then(m => m.default || m)
  },
  {
    name: "reisen-ch___de",
    path: "/de/reisen-ch/",
    component: () => import("/home/vfy/website-git-prod/pages/reisen-ch.vue").then(m => m.default || m)
  },
  {
    name: "reisen-ch___fr",
    path: "/fr/reisen-ch/",
    component: () => import("/home/vfy/website-git-prod/pages/reisen-ch.vue").then(m => m.default || m)
  },
  {
    name: "reisen-de___nl",
    path: "/nl/reisen-de/",
    component: () => import("/home/vfy/website-git-prod/pages/reisen-de.vue").then(m => m.default || m)
  },
  {
    name: "reisen-de___en",
    path: "/en/reisen-de/",
    component: () => import("/home/vfy/website-git-prod/pages/reisen-de.vue").then(m => m.default || m)
  },
  {
    name: "reisen-de___de",
    path: "/de/reisen-de/",
    component: () => import("/home/vfy/website-git-prod/pages/reisen-de.vue").then(m => m.default || m)
  },
  {
    name: "reisen-de___fr",
    path: "/fr/reisen-de/",
    component: () => import("/home/vfy/website-git-prod/pages/reisen-de.vue").then(m => m.default || m)
  },
  {
    name: "sp-partnerCode-path___nl",
    path: "/nl/sp-:partnerCode()/:path(.*)*/",
    component: () => import("/home/vfy/website-git-prod/pages/sp-[partnerCode]/[...path].vue").then(m => m.default || m)
  },
  {
    name: "sp-partnerCode-path___en",
    path: "/en/sp-:partnerCode()/:path(.*)*/",
    component: () => import("/home/vfy/website-git-prod/pages/sp-[partnerCode]/[...path].vue").then(m => m.default || m)
  },
  {
    name: "sp-partnerCode-path___de",
    path: "/de/sp-:partnerCode()/:path(.*)*/",
    component: () => import("/home/vfy/website-git-prod/pages/sp-[partnerCode]/[...path].vue").then(m => m.default || m)
  },
  {
    name: "sp-partnerCode-path___fr",
    path: "/fr/sp-:partnerCode()/:path(.*)*/",
    component: () => import("/home/vfy/website-git-prod/pages/sp-[partnerCode]/[...path].vue").then(m => m.default || m)
  },
  {
    name: "sterrenclassificatie___nl",
    path: "/nl/sterrenclassificatie/",
    component: () => import("/home/vfy/website-git-prod/pages/sterrenclassificatie.vue").then(m => m.default || m)
  },
  {
    name: "sterrenclassificatie___en",
    path: "/en/star-qualification/",
    component: () => import("/home/vfy/website-git-prod/pages/sterrenclassificatie.vue").then(m => m.default || m)
  },
  {
    name: "sterrenclassificatie___de",
    path: "/de/sterne-qualifikation/",
    component: () => import("/home/vfy/website-git-prod/pages/sterrenclassificatie.vue").then(m => m.default || m)
  },
  {
    name: "sterrenclassificatie___fr",
    path: "/fr/classement-par-etoiles/",
    component: () => import("/home/vfy/website-git-prod/pages/sterrenclassificatie.vue").then(m => m.default || m)
  },
  {
    name: "succes-aanvraag___nl",
    path: "/nl/succes/aanvraag/",
    component: () => import("/home/vfy/website-git-prod/pages/succes/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: "succes-aanvraag___en",
    path: "/en/success/application/",
    component: () => import("/home/vfy/website-git-prod/pages/succes/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: "succes-aanvraag___de",
    path: "/de/erfolg/anwendung/",
    component: () => import("/home/vfy/website-git-prod/pages/succes/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: "succes-aanvraag___fr",
    path: "/fr/succes/application/",
    component: () => import("/home/vfy/website-git-prod/pages/succes/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: "succes-boeking___nl",
    path: "/nl/succes/boeking/",
    component: () => import("/home/vfy/website-git-prod/pages/succes/boeking.vue").then(m => m.default || m)
  },
  {
    name: "succes-boeking___en",
    path: "/en/success/booking/",
    component: () => import("/home/vfy/website-git-prod/pages/succes/boeking.vue").then(m => m.default || m)
  },
  {
    name: "succes-boeking___de",
    path: "/de/erfolg/buchung/",
    component: () => import("/home/vfy/website-git-prod/pages/succes/boeking.vue").then(m => m.default || m)
  },
  {
    name: "succes-boeking___fr",
    path: "/fr/succes/reservation/",
    component: () => import("/home/vfy/website-git-prod/pages/succes/boeking.vue").then(m => m.default || m)
  },
  {
    name: "succes-optie___nl",
    path: "/nl/succes/optie/",
    component: () => import("/home/vfy/website-git-prod/pages/succes/optie.vue").then(m => m.default || m)
  },
  {
    name: "succes-optie___en",
    path: "/en/success/option/",
    component: () => import("/home/vfy/website-git-prod/pages/succes/optie.vue").then(m => m.default || m)
  },
  {
    name: "succes-optie___de",
    path: "/de/erfolg/moglichkeit/",
    component: () => import("/home/vfy/website-git-prod/pages/succes/optie.vue").then(m => m.default || m)
  },
  {
    name: "succes-optie___fr",
    path: "/fr/succes/option/",
    component: () => import("/home/vfy/website-git-prod/pages/succes/optie.vue").then(m => m.default || m)
  },
  {
    name: "team___nl",
    path: "/nl/team-villa-for-you/",
    component: () => import("/home/vfy/website-git-prod/pages/team.vue").then(m => m.default || m)
  },
  {
    name: "team___en",
    path: "/en/team-villa-for-you/",
    component: () => import("/home/vfy/website-git-prod/pages/team.vue").then(m => m.default || m)
  },
  {
    name: "team___de",
    path: "/de/team-villa-for-you/",
    component: () => import("/home/vfy/website-git-prod/pages/team.vue").then(m => m.default || m)
  },
  {
    name: "team___fr",
    path: "/fr/team-villa-for-you/",
    component: () => import("/home/vfy/website-git-prod/pages/team.vue").then(m => m.default || m)
  },
  {
    name: "vacances-ch___nl",
    path: "/nl/vacances-ch/",
    component: () => import("/home/vfy/website-git-prod/pages/vacances-ch.vue").then(m => m.default || m)
  },
  {
    name: "vacances-ch___en",
    path: "/en/vacances-ch/",
    component: () => import("/home/vfy/website-git-prod/pages/vacances-ch.vue").then(m => m.default || m)
  },
  {
    name: "vacances-ch___de",
    path: "/de/vacances-ch/",
    component: () => import("/home/vfy/website-git-prod/pages/vacances-ch.vue").then(m => m.default || m)
  },
  {
    name: "vacances-ch___fr",
    path: "/fr/vacances-ch/",
    component: () => import("/home/vfy/website-git-prod/pages/vacances-ch.vue").then(m => m.default || m)
  },
  {
    name: "vacances___nl",
    path: "/nl/vacances/",
    component: () => import("/home/vfy/website-git-prod/pages/vacances.vue").then(m => m.default || m)
  },
  {
    name: "vacances___en",
    path: "/en/vacances/",
    component: () => import("/home/vfy/website-git-prod/pages/vacances.vue").then(m => m.default || m)
  },
  {
    name: "vacances___de",
    path: "/de/vacances/",
    component: () => import("/home/vfy/website-git-prod/pages/vacances.vue").then(m => m.default || m)
  },
  {
    name: "vacances___fr",
    path: "/fr/vacances/",
    component: () => import("/home/vfy/website-git-prod/pages/vacances.vue").then(m => m.default || m)
  },
  {
    name: "vacatures___nl",
    path: "/nl/vacatures/",
    component: () => import("/home/vfy/website-git-prod/pages/vacatures.vue").then(m => m.default || m)
  },
  {
    name: "vacatures___en",
    path: "/en/vacancies/",
    component: () => import("/home/vfy/website-git-prod/pages/vacatures.vue").then(m => m.default || m)
  },
  {
    name: "vacatures___de",
    path: "/de/stellenangebote/",
    component: () => import("/home/vfy/website-git-prod/pages/vacatures.vue").then(m => m.default || m)
  },
  {
    name: "vacatures___fr",
    path: "/fr/postes-vacants/",
    component: () => import("/home/vfy/website-git-prod/pages/vacatures.vue").then(m => m.default || m)
  },
  {
    name: "vakantiehuis-country-slug___nl",
    path: "/nl/vakantiehuis-:country()/:slug(.*)*/",
    meta: _91_46_46_46slug_93tDiJeRUwMlMeta || {},
    component: () => import("/home/vfy/website-git-prod/pages/vakantiehuis-[country]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "vakantiehuis-country-slug___en",
    path: "/en/holiday-home-:country()/:slug(.*)*/",
    meta: _91_46_46_46slug_93tDiJeRUwMlMeta || {},
    component: () => import("/home/vfy/website-git-prod/pages/vakantiehuis-[country]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "vakantiehuis-country-slug___de",
    path: "/de/ferienhaus-:country()/:slug(.*)*/",
    meta: _91_46_46_46slug_93tDiJeRUwMlMeta || {},
    component: () => import("/home/vfy/website-git-prod/pages/vakantiehuis-[country]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "vakantiehuis-country-slug___fr",
    path: "/fr/maison-de-vacances-:country()/:slug(.*)*/",
    meta: _91_46_46_46slug_93tDiJeRUwMlMeta || {},
    component: () => import("/home/vfy/website-git-prod/pages/vakantiehuis-[country]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "vakantiehuis___nl",
    path: "/nl/vakantiehuis/",
    meta: vakantiehuish4Ihse8GEVMeta || {},
    component: () => import("/home/vfy/website-git-prod/pages/vakantiehuis.vue").then(m => m.default || m)
  },
  {
    name: "vakantiehuis___en",
    path: "/en/holiday-home/",
    meta: vakantiehuish4Ihse8GEVMeta || {},
    component: () => import("/home/vfy/website-git-prod/pages/vakantiehuis.vue").then(m => m.default || m)
  },
  {
    name: "vakantiehuis___de",
    path: "/de/ferienhaus/",
    meta: vakantiehuish4Ihse8GEVMeta || {},
    component: () => import("/home/vfy/website-git-prod/pages/vakantiehuis.vue").then(m => m.default || m)
  },
  {
    name: "vakantiehuis___fr",
    path: "/fr/maison-de-vacances/",
    meta: vakantiehuish4Ihse8GEVMeta || {},
    component: () => import("/home/vfy/website-git-prod/pages/vakantiehuis.vue").then(m => m.default || m)
  },
  {
    name: "vakantiehuizen-be___nl",
    path: "/nl/vakantiehuizen-be/",
    component: () => import("/home/vfy/website-git-prod/pages/vakantiehuizen-be.vue").then(m => m.default || m)
  },
  {
    name: "vakantiehuizen-be___en",
    path: "/en/vakantiehuizen-be/",
    component: () => import("/home/vfy/website-git-prod/pages/vakantiehuizen-be.vue").then(m => m.default || m)
  },
  {
    name: "vakantiehuizen-be___de",
    path: "/de/vakantiehuizen-be/",
    component: () => import("/home/vfy/website-git-prod/pages/vakantiehuizen-be.vue").then(m => m.default || m)
  },
  {
    name: "vakantiehuizen-be___fr",
    path: "/fr/vakantiehuizen-be/",
    component: () => import("/home/vfy/website-git-prod/pages/vakantiehuizen-be.vue").then(m => m.default || m)
  },
  {
    name: "vakantiehuizen___nl",
    path: "/nl/vakantiehuizen/",
    component: () => import("/home/vfy/website-git-prod/pages/vakantiehuizen.vue").then(m => m.default || m)
  },
  {
    name: "vakantiehuizen___en",
    path: "/en/vakantiehuizen/",
    component: () => import("/home/vfy/website-git-prod/pages/vakantiehuizen.vue").then(m => m.default || m)
  },
  {
    name: "vakantiehuizen___de",
    path: "/de/vakantiehuizen/",
    component: () => import("/home/vfy/website-git-prod/pages/vakantiehuizen.vue").then(m => m.default || m)
  },
  {
    name: "vakantiehuizen___fr",
    path: "/fr/vakantiehuizen/",
    component: () => import("/home/vfy/website-git-prod/pages/vakantiehuizen.vue").then(m => m.default || m)
  },
  {
    name: "veelgestelde-vragen___nl",
    path: "/nl/veelgestelde-vragen/",
    component: () => import("/home/vfy/website-git-prod/pages/veelgestelde-vragen.vue").then(m => m.default || m)
  },
  {
    name: "veelgestelde-vragen___en",
    path: "/en/frequently-asked-questions/",
    component: () => import("/home/vfy/website-git-prod/pages/veelgestelde-vragen.vue").then(m => m.default || m)
  },
  {
    name: "veelgestelde-vragen___de",
    path: "/de/haufig-gestellte-fragen/",
    component: () => import("/home/vfy/website-git-prod/pages/veelgestelde-vragen.vue").then(m => m.default || m)
  },
  {
    name: "veelgestelde-vragen___fr",
    path: "/fr/questions-frequentes/",
    component: () => import("/home/vfy/website-git-prod/pages/veelgestelde-vragen.vue").then(m => m.default || m)
  },
  {
    name: "verzekeringen___nl",
    path: "/nl/verzekeringen/",
    component: () => import("/home/vfy/website-git-prod/pages/verzekeringen.vue").then(m => m.default || m)
  },
  {
    name: "verzekeringen___en",
    path: "/en/insurances/",
    component: () => import("/home/vfy/website-git-prod/pages/verzekeringen.vue").then(m => m.default || m)
  },
  {
    name: "verzekeringen___de",
    path: "/de/versicherungen/",
    component: () => import("/home/vfy/website-git-prod/pages/verzekeringen.vue").then(m => m.default || m)
  },
  {
    name: "verzekeringen___fr",
    path: "/fr/assurances/",
    component: () => import("/home/vfy/website-git-prod/pages/verzekeringen.vue").then(m => m.default || m)
  },
  {
    name: "villas___nl",
    path: "/nl/villas/",
    component: () => import("/home/vfy/website-git-prod/pages/villas.vue").then(m => m.default || m)
  },
  {
    name: "villas___en",
    path: "/en/villas/",
    component: () => import("/home/vfy/website-git-prod/pages/villas.vue").then(m => m.default || m)
  },
  {
    name: "villas___de",
    path: "/de/villas/",
    component: () => import("/home/vfy/website-git-prod/pages/villas.vue").then(m => m.default || m)
  },
  {
    name: "villas___fr",
    path: "/fr/villas/",
    component: () => import("/home/vfy/website-git-prod/pages/villas.vue").then(m => m.default || m)
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/nl/nieuwsbrief",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/nl/golfbaan-spierdijk",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/nl/win",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/en/win",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/de/win",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/fr/gagner",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/nl/groepshuizengids",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/nl/park/grebenzen-lodges",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/nl/park/kreischberg",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/nl/park/mountain-chalets",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/nl/park/riesneralm-chalets",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/nl/pers",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/nl/pers/villa_for_you_groeit_uit_tot_grote_speler_op_vakantiemarkt",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/nl/pers/rechtbank_stelt_villa_for_you_in_het_gelijk",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/nl/kortingscode",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/en/discount_code",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/de/rabattcode",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/fr/codes_de_reduction",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/nl/ons-aanbod",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/ons-aanbod",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/en/our-offer",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/our-offer",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/de/unser-angebot",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/unser-angebot",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/fr/notre-offre",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/notre-offre",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/mittersill",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/nl/algemene-voorwaarden",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/en/terms-and-conditions",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/de/bedingungen",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/fr/conditions-generales",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/verhuren",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/vermieten",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/louer",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/rent-out",
    component: component_45stub1ScE3GkDJw
  },
  {
    name: component_45stub1ScE3GkDJwMeta?.name,
    path: "/de/contact",
    component: component_45stub1ScE3GkDJw
  }
]